import request from '@/utils/request'

export const headList = [
    {name:'质检单号',prop:'qcNo',width:120},
    {name:'图片',prop:'imageUrl'},
    {name:'品名/SKU',prop:'title'},
    {name:'店铺/FNSKU',prop:''},
    {name:'采购单号',prop:'purchaseNo',width:130},
    {name:'采购员',prop:'purchaseByName'},
    {name:'收货人',prop:'receiveByName'},
    {name:'到货时间',prop:'receiveTime',width:150},
    {name:'质检量',prop:'checkingQuantity'},
    {name:'质检员',prop:'checkByName'},
    {name:'质检时间',prop:'checkTime',width:150},
    {name:'状态',prop:'status',width:120},
]

export function pageInit(){
    return request({
        url:'/api/warehouseQualityCheckNote/pageInit',
        method:'get',
    })
}
export function listPage(params){
    return request({
        url:'/api/warehouseQualityCheckNote/listPage',
        method:'get',
        params
    })
}
export function detail(id){
    return request({
        url:`/api/warehouseQualityCheckNote/${id}`,
        method:'get',
    })
}
export function cancelQualityCheck(id){
    return request({
        url:`/api/warehouseQualityCheckNote/cancelQualityCheck/${id}`,
        method:'put',
    })
}

export function revokedQualityCheck(id){
    return request({
        url:`/api/warehouseQualityCheckNote/revokedQualityCheck/${id}`,
        method:'put',
    })
}

// 完成质检
export function batchFinishQualityCheck(data) {
    return request({
        url:'/api/warehouseQualityCheckNote/batchFinishQualityCheck',
        method:'post',
        data

    });
}
// 批量完成质检
export function batchFinishQualityCheckInit(data) {
    return request({
        url:'/api/warehouseQualityCheckNote/batchFinishQualityCheckInit',
        method:'post',
        data
    });
}

export function getFile(id) {
    return request({
        url:`/api/file/upload/getFile/${id}`,
        method:'get',
        responseType: "blob"
    })
}
