<template>
    <div class="Box">
        <div>
            <el-select clearable @clear="find" v-model="pageInfo.timeType" style="margin-right:2px;width:100px" placeholder="质检时间">
                <el-option
                    v-for="item in timeSearchTypeList"
                    :key="item.id"
                    :label="item.value"
                    :value="item.id">
                </el-option>
            </el-select>
            <el-date-picker
            clearable @clear="find"
            style="margin-right:10px;position:relative;top:1px"
            v-model="pageInfo.time"
            type="daterange"
            range-separator="至"
            format="yyyy-MM-dd"
            @blur='getTime'
            :picker-options="pickerOptions"
            value-format="yyyy-MM-dd"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
            </el-date-picker>
            <el-select clearable @clear="find" v-model="pageInfo.checkStatus" style="margin-right:10px;width:100px" placeholder="质检状态">
                <el-option
                    v-for="item in checkStatusList"
                    :key="item.id"
                    :label="item.value"
                    :value="item.id">
                </el-option>
            </el-select>
            <el-input clearable @clear="find" placeholder="搜索内容" v-model="pageInfo.keyword" style="width:300px;margin-right:10px">
                <el-select v-model="pageInfo.searchType" style="width:100px" slot="prepend">
                    <el-option
                        v-for="item in searchTypeList"
                        :key="item.id"
                        :label="item.value"
                        :value="item.id">
                    </el-option>
                </el-select>
            </el-input>
            <el-button type="primary" @click="find">查询</el-button><br><br>
            <el-button @click="batchFinishQualityCheckInit">批量完成质检</el-button>
            <el-button>批量退换货</el-button>
            <TableDialog :tableList='headList' @setTableHead='gethead' style="float:right"></TableDialog>
        </div>
        <div class="content">
            <el-table
                v-loading='loading'
                height="100%"
                :data="tableData"
                @selection-change="batchSelect"
                style="width: 100%"
                :cell-style="{ fontSize: '13px', padding: '7px 0' }"
                :header-cell-style="{
                    fontSize: '13px',
                    padding: '9px 0',
                    marginTop: '10px',
                    background: '#EBEEF5',
                }">
                <el-table-column
                    type="selection"
                    width="55">
                </el-table-column>
                <el-table-column
                    v-for="item in head"
                    :key="item.prop"
                    :prop="item.prop"
                    :label="item.name"
                    :min-width="item.width">
                    <template slot-scope="scope">
                        <CopyButton v-if="item.prop=='qcNo'" :content='scope.row.qcNo'>
                            <el-button slot="copy" type='text' v-if="item.prop=='qcNo'" @click="getDetail(scope.row.id,'质检单详情')">{{scope.row.qcNo}}</el-button>
                        </CopyButton>
                        
                        <Picture v-else-if="item.prop=='imageUrl'" :url='scope.row[prop]' width='40'></Picture>
                        
                        <div v-else-if="item.prop=='title'">
                            <p :title="scope.row.title" class="oneline">{{scope.row.title}}</p>
                            <p :title="scope.row.sku" class="oneline">{{scope.row.sku}}</p>
                        </div>
                        
                        <div v-else-if="item.prop=='status'" class="status">
                            <span style="color:#4AB615;border-color:#4AB615" v-if="scope.row.status=='checking'">质检中</span>
                            <span v-if="scope.row.status=='checked'">已质检</span>
                            <span v-if="scope.row.status=='exemptChecked'">已免检</span>
                            <span v-if="scope.row.status=='revoked'">已质检(已撤销)</span>
                            <span v-if="scope.row.status=='exemptRevoked'">已免检(已撤销)</span>
                        
                        </div>
                        <span v-else>{{scope.row[item.prop]}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="prop"
                    width="170"
                    label="操作" >
                    <template slot-scope="scope">
                        <el-button type="text" @click="getDetail(scope.row.id,'质检单详情')">详情</el-button>
                        <el-button v-if="scope.row.status=='checked'||scope.row.status=='exemptChecked'" type="text" @click="revokedQualityCheck(scope.row.id,scope.row.qcNo)">撤销</el-button>
                        <el-button v-if="scope.row.status=='checked'" type="text" @click="getDetail(scope.row.id)">退换货</el-button>
                        <el-button v-if="scope.row.status=='checking'" type="text" @click="cancelCheck(scope.row.id,scope.row.qcNo)">取消质检</el-button>
                        <el-button v-if="scope.row.status=='checking'" type="text" @click="getDetail(scope.row.id,'完成质检')">完成质检</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div style="text-align:center;border-top:1px solid #E4E7ED;padding:10px">
            <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageInfo.current"
            :page-sizes="[20, 30, 40]"
            :page-size="20"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
            </el-pagination>
        </div>
        <!-- 批量完成质检 -->
        <el-dialog
            title="批量完成质检"
            :visible.sync="batchFinish"
            width="80%"
            :before-close="batchFinishClose">
            <div v-loading=dialogLoading>
                <el-button type="primary">导入质检结果</el-button>
                <el-table
                :data='batchFinishQualityCheckInitList'
                style="width: 100%; margin-top: 10px"
                height="400px"
                :cell-style="{ fontSize: '13px', padding: '7px 0',textAlign:'center', }"
                :header-cell-style="{
                    textAlign:'center',
                    fontSize: '13px',
                    padding: '9px 0',
                    marginTop: '10px',
                    background: '#EBEEF5',
                }">
                    <el-table-column
                        prop="qcNo"
                        label="质检单号" >
                    </el-table-column>
                    <el-table-column
                        prop="prop"
                        label="图片" >
                        <template slot-scope="scope">
                            <Picture style="margin:auto" :url='scope.row.imageUrl' width='40'></Picture>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="prop"
                        label="品名/SKU" >
                        <template slot-scope="scope">
                            <p  style="text-align:center" :title="scope.row.title" class="oneline">{{scope.row.title}}</p>
                            <p  style="text-align:center" :title="scope.row.sku" class="oneline">{{scope.row.sku}}</p>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="prop"
                        label="店铺/FNSKU" >
                    </el-table-column>
                    <el-table-column
                        prop="checkingQuantity"
                        label="质检量" >
                    </el-table-column>
                    <el-table-column
                        prop="spotCheckQuantity"
                        label="抽检量" >
                        <template slot-scope="scope">
                            <el-input
                            @blur="scope.row.badQuantity=scope.row.spotCheckBadQuantity"
                            onkeyup="value=value.replace(/^\.+|[^\d.]/g,'')"
                            @input="scope.row.spotCheckQuantity=scope.row.spotCheckQuantity>scope.row.checkingQuantity?scope.row.checkingQuantity:scope.row.spotCheckQuantity"
                            v-model="scope.row.spotCheckQuantity" 
                            placeholder="抽检量"></el-input>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="spotCheckBadQuantity"
                        label="抽检次品量" 
                        >
                        <template slot-scope="scope">
                            <el-input 
                            v-model="scope.row.spotCheckBadQuantity" 
                            placeholder="抽检次品量"
                            onkeyup="value=value.replace(/^\.+|[^\d.]/g,'')"
                            @input="scope.row.spotCheckBadQuantity=scope.row.spotCheckBadQuantity>scope.row.spotCheckQuantity?scope.row.spotCheckQuantity:scope.row.spotCheckBadQuantity"></el-input>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="goodQuantity"
                        label="总可用量" >
                        <template slot-scope="scope">
                            <el-input 
                            v-model="scope.row.goodQuantity" 
                            placeholder="总可用量"></el-input>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="badQuantity"
                        label="总次品量" >
                        <template slot-scope="scope">
                            <el-input 
                            onkeyup="value=value.replace(/^\.+|[^\d.]/g,'')"
                            v-model="scope.row.badQuantity" 
                            @input="scope.row.badQuantity=scope.row.badQuantity>scope.row.checkingQuantity?scope.row.checkingQuantity:scope.row.badQuantity"
                            placeholder="总次品量"></el-input>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="tips"
                        label="备注" >
                        <template slot-scope="scope">
                            <el-input 
                            v-model="scope.row.tips" 
                            placeholder="备注"></el-input>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="prop"
                        label="上传照片" 
                        width="150">
                        <template slot-scope="scope">
                            <div v-if="scope.row.picList.length!=0" style="display:inline-block;margin-top:15px">
                                <el-popover
                                placement="bottom"
                                width="200"
                                trigger="hover">
                                <div>
                                    <Picture v-for="(item,index) in scope.row.picList" style="margin:7px;display:inline-block" :key="index" :url='item.imageUrl' width='50'></Picture>
                                </div>
                                <el-badge slot="reference" :value="scope.row.picList.length" class="item" type="success">
                                    <Picture :url='scope.row.picList[0].imageUrl' width='30'></Picture>
                                </el-badge>
                                </el-popover>
                            </div>
                            <div style="display:inline-block">
                                <el-upload
                                :action="action"
                                :headers="mytoken"
                                :show-file-list='false'
                                :on-success="batUploadPic"
                                list-type="picture"
                                accept=".jpg,.png"
                                multiple
                                >
                                <el-button @click="index=scope.$index" size="small" type="text">上传</el-button>
                                </el-upload>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div slot="footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button :loading='dialogLoading' type="primary" @click="batchCheckSure">确 定</el-button>
            </div>
        </el-dialog>
        <!-- 批量退换货 -->
        <el-drawer
        title="批量退换货"
        :visible.sync="batchReturn"
        direction="btt"
        size='88%'
        max-height='500px'
        :before-close="batchReturnClose">
        <div style="padding:20px">
            <h3 style="margin-bottom:30px">批量退换货</h3>
            <el-button type="primary">批量添加采购单</el-button>
            <el-button>批量移除</el-button> <br><br>
            <el-table
            :cell-style="{ fontSize: '13px', padding: '7px 0' }"
            :header-cell-style="{
                fontSize: '13px',
                padding: '9px 0',
                marginTop: '10px',
                background: '#EBEEF5',
            }"
            >
                <el-table-column
                    type="selection"
                    width="55" >
                </el-table-column>
                <el-table-column
                    prop="prop"
                    label="采购单号" >
                </el-table-column>
                <el-table-column
                    prop="prop"
                    label="供应商" >
                </el-table-column>
                <el-table-column
                    prop="prop"
                    label="采购仓库" >
                </el-table-column>
                <el-table-column
                    prop="prop"
                    label="退货方式" >
                </el-table-column>
                <el-table-column
                    prop="prop"
                    label="补货方式" >
                </el-table-column>
                <el-table-column
                    prop="prop"
                    label="采购币种" >
                </el-table-column>
                <el-table-column
                    prop="prop"
                    label="退货运费" >
                </el-table-column>
                <el-table-column
                    prop="prop"
                    label="其他费用" >
                </el-table-column>
                <el-table-column
                    prop="prop"
                    label="费用分摊方式" >
                </el-table-column>
                <el-table-column
                    prop="prop"
                    label="退货原因" >
                </el-table-column>
                <el-table-column
                    prop="prop"
                    label="备注" >
                </el-table-column>
                <el-table-column
                    prop="prop"
                    label="附件" >
                </el-table-column>
                <el-table-column
                    prop="prop"
                    label="操作" >
                </el-table-column>
            </el-table><br><br>
            <el-button type="primary">添加产品</el-button>
            <el-button>批量移除</el-button> <br><br>
            <el-table
            :cell-style="{ fontSize: '13px', padding: '7px 0' }"
            :header-cell-style="{
                fontSize: '13px',
                padding: '9px 0',
                marginTop: '10px',
                background: '#EBEEF5',
            }"
            >
                <el-table-column
                    type="selection"
                    width="55" >
                </el-table-column>
                <el-table-column
                    prop="prop"
                    label="图片" >
                </el-table-column>
                <el-table-column
                    prop="prop"
                    label="品名/SKU" >
                </el-table-column>
                <el-table-column
                    prop="prop"
                    label="店铺" >
                </el-table-column>
                <el-table-column
                    prop="prop"
                    label="MSKU/FNSKU" >
                </el-table-column>
                <el-table-column
                    prop="prop"
                    label="含税单价(¥)" >
                </el-table-column>
                <el-table-column
                    prop="prop"
                    label="采购数量" >
                </el-table-column>
                <el-table-column
                    prop="prop"
                    label="良品可退量" >
                </el-table-column>
                <el-table-column
                    prop="prop"
                    label="良品退货量" >
                </el-table-column>
                <el-table-column
                    prop="prop"
                    label="次品可退量" >
                </el-table-column>
                <el-table-column
                    prop="prop"
                    label="次品退货量" >
                </el-table-column>
                <el-table-column
                    prop="prop"
                    label="扣款数量" >
                </el-table-column>
                <el-table-column
                    prop="prop"
                    label="退货金额(¥)" >
                </el-table-column>
                <el-table-column
                    prop="prop"
                    label="备注" >
                </el-table-column>
                <el-table-column
                    prop="prop"
                    label="操作" >
                </el-table-column>
            </el-table>
            <div style="text-align:center;padding:30px">
                <el-button style="width:130px">关闭</el-button>
                <el-button type="primary" style="width:130px">保存</el-button>
            </div>
        </div>
        </el-drawer>
        <!-- 添加采购单 -->
        <el-dialog
            title="添加采购单"
            :visible.sync="addOrderBox"
            width="width"
            :before-close="addOrderBoxClose">
            <div>
                <el-select placeholder="全部仓库" style="margin-right:10px;width:100px"></el-select>
                <el-input v-model="model" placeholder="搜索内容" style="width:200px;margin-right:10px"></el-input>
                <el-button type="primary">查询</el-button> <br><br>
                <el-table
                :cell-style="{ fontSize: '13px', padding: '7px 0' }"
                :header-cell-style="{
                    fontSize: '13px',
                    padding: '9px 0',
                    marginTop: '10px',
                    background: '#EBEEF5',
                }">
                    <el-table-column
                        width="55"
                        type="selection">
                    </el-table-column>
                    <el-table-column
                        prop="prop"
                        label="采购单号" >
                    </el-table-column>
                    <el-table-column
                        prop="prop"
                        label="供应商" >
                    </el-table-column>
                    <el-table-column
                        prop="prop"
                        label="采购仓库" >
                    </el-table-column>
                    <el-table-column
                        prop="prop"
                        label="采购员" >
                    </el-table-column>
                    <el-table-column
                        prop="prop"
                        label="创建时间" >
                    </el-table-column>
                    <el-table-column
                        prop="prop"
                        label="状态" >
                    </el-table-column>
                </el-table>
                <div style="text-align:center;padding:10px">
                    <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="currentPage4"
                    :page-sizes="[100, 200, 300, 400]"
                    :page-size="100"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="400">
                    </el-pagination>
                </div>
            </div>
            <div slot="footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
            </div>
        </el-dialog>
        <!-- 添加退货商品 -->
        <el-dialog
            title="添加退货商品"
            :visible.sync="addReturnGoods"
            width="width"
            :before-close="addReturnGoodsClose">
            <div>
                <el-select placeholder="全部仓库" style="margin-right:10px;width:100px"></el-select>
                <el-input v-model="model" placeholder="搜索内容" style="width:200px;margin-right:10px"></el-input>
                <el-button type="primary">查询</el-button> <br><br>
                <el-table
                height="400px"
                :cell-style="{ fontSize: '13px', padding: '7px 0' }"
                :header-cell-style="{
                    fontSize: '13px',
                    padding: '9px 0',
                    marginTop: '10px',
                    background: '#EBEEF5',
                }">
                    <el-table-column
                        width="55"
                        type="selection">
                    </el-table-column>
                    <el-table-column
                        prop="prop"
                        label="图片" >
                    </el-table-column>
                    <el-table-column
                        prop="prop"
                        label="款名/SPU" >
                    </el-table-column>
                    <el-table-column
                        prop="prop"
                        label="品名/SKU" >
                    </el-table-column>
                    <el-table-column
                        prop="prop"
                        label="属性" >
                    </el-table-column>
                    <el-table-column
                        prop="prop"
                        label="MSKU/FNSKU" >
                    </el-table-column>
                    <el-table-column
                        prop="prop"
                        label="采购数量" >
                    </el-table-column>
                    <el-table-column
                        prop="prop"
                        label="到货量" >
                    </el-table-column>
                    <el-table-column
                        prop="prop"
                        label="良品量" >
                    </el-table-column>
                    <el-table-column
                        prop="prop"
                        label="次品量" >
                    </el-table-column>
                </el-table>
                <div style="text-align:center;padding:10px">
                    <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="currentPage4"
                    :page-sizes="[100, 200, 300, 400]"
                    :page-size="100"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="400">
                    </el-pagination>
                </div>
            </div>
            <div slot="footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
            </div>
        </el-dialog>
        <!-- 质检单详情 -->
        <el-dialog
            :title="title"
            :visible.sync="detailBox"
            width="width"
            :destroy-on-close='true'
            :before-close="detailClose">
            <div>
                <h3>
                    <span style="margin-right:10px;font-weight:0">质检单号 {{detailInfo.qcNo}}</span> 
                    <div class="status" style="display:inline-block;font-size:14px">
                        <span style="color:#4AB615;border-color:#4AB615" v-if="detailInfo.status=='checking'">质检中</span>
                        <span v-if="detailInfo.status=='checked'">已质检</span>
                        <span v-if="detailInfo.status=='exemptChecked'">已免检</span>
                        <span v-if="detailInfo.status=='revoked'">已质检(已撤销)</span>
                        <span v-if="detailInfo.status=='exemptRevoked'">已免检(已撤销)</span>
                    </div>
                </h3>
                <div style="display:flex;margin-top:10px;align-items:center">
                    <div style="width:70px;overflow:hidden;height:70px;border-radius:6px;">
                        <Picture :url='detailInfo.imageUrl' width='70'></Picture>
                    </div>
                    <div style="line-height:20px;margin-left:10px;font-size:12px">
                        <p>{{detailInfo.title}}</p>
                        <p>SKU：{{detailInfo.sku}}</p>
                        <p>FNSKU：</p>
                    </div>
                </div>
                <div class="detail">
                    <div>
                        <div>采购单号：</div>
                        <div>{{detailInfo.purchaseNo}}</div>
                    </div>
                    <div>
                        <div>采购仓库：</div>
                        <div>{{detailInfo.storageName}}</div>
                    </div>
                    <div>
                        <div>采购员：</div>
                        <div>{{detailInfo.purchaseByName}}</div>
                    </div>
                    <div>
                        <div>质检量：</div>
                        <div>{{detailInfo.checkingQuantity}}</div>
                    </div>
                    <div>
                        <div>签收人：</div>
                        <div>{{detailInfo.createByName}}</div>
                    </div>
                    <div>
                        <div>采到货时间：</div>
                        <div>{{detailInfo.receiveTime}}</div>
                    </div>
                </div>
                <h3 v-if="detailInfo.status=='checked'||title=='完成质检'">质检结果</h3><br><br>
                <el-form v-loading="dialogLoading" :rules="qualityRules" ref="quality" :model="qualityInfo" v-if="detailInfo.status=='checked'||title=='完成质检'" label-width="100px" :inline='true'>
                    <el-form-item 
                    style="width:32%" 
                    label="抽检量:"
                    prop="spotCheckQuantity">
                        <el-input v-if="title=='完成质检'" onkeyup="value=value.replace(/^\.+|[^\d.]/g,'')" v-model="qualityInfo.spotCheckQuantity" placeholder="抽检量"></el-input>
                        <span v-else>{{detailInfo.spotCheckQuantity}}</span>
                    </el-form-item>
                    <el-form-item 
                    style="width:32%" 
                    label="抽检次品量:"
                    prop="spotCheckBadQuantity">
                        <el-input v-if="title=='完成质检'" @input="qualityInfo.badQuantity=qualityInfo.spotCheckBadQuantity" onkeyup="value=value.replace(/^\.+|[^\d.]/g,'')" v-model="qualityInfo.spotCheckBadQuantity" placeholder="抽检次品量"></el-input>
                        <span v-else>{{detailInfo.spotCheckBadQuantity}}</span>
                    </el-form-item>
                    <el-form-item 
                    style="width:32%" 
                    label="抽检合格率:">
                        <el-input v-if="title=='完成质检'" :disabled='true' v-model="percentPass" placeholder="抽检合格率"></el-input>
                        <span v-else>{{(detailInfo.spotCheckQuantity-detailInfo.spotCheckBadQuantity)/detailInfo.spotCheckQuantity | Digits}}</span>
                    </el-form-item>
                    <el-form-item style="width:32%" label="抽检比例:">
                        <el-input v-if="title=='完成质检'" :disabled='true' v-model="samplingRatio" placeholder="抽检比例"></el-input>
                        <span v-else>{{detailInfo.spotCheckQuantity/detailInfo.checkingQuantity | Digits}}</span>
                    </el-form-item>
                    <el-form-item 
                    style="width:32%" 
                    label="总良品量:"
                    prop="goodQuantity">
                        <el-input v-if="title=='完成质检'" @input="qualityInfo.badQuantity=detailInfo.checkingQuantity-qualityInfo.goodQuantity" onkeyup="value=value.replace(/^\.+|[^\d.]/g,'')" v-model="qualityInfo.goodQuantity" placeholder="总良品量"></el-input>
                        <span v-else>{{detailInfo.goodQuantity}}</span>
                    </el-form-item>
                    <el-form-item 
                    style="width:32%" 
                    label="总次品量:"
                    prop="badQuantity">
                        <el-input v-if="title=='完成质检'" @input="qualityInfo.goodQuantity=detailInfo.checkingQuantity-qualityInfo.badQuantity" onkeyup="value=value.replace(/^\.+|[^\d.]/g,'')" v-model="qualityInfo.badQuantity" placeholder="总次品量"></el-input>
                        <span v-else>{{detailInfo.badQuantity}}</span>
                    </el-form-item>
                    <el-form-item style="width:32%" label="质检员:" v-if="title!='完成质检'">
                        <el-input v-if="title=='完成质检'" v-model="detailInfo.spotCheckQuantity" placeholder="质检员"></el-input>
                        <span v-else>{{detailInfo.checkByName}}</span>
                    </el-form-item>
                    <el-form-item style="width:42%" label="质检时间:" v-if="title!='完成质检'">
                        <el-input v-if="title=='完成质检'" v-model="detailInfo.spotCheckQuantity" placeholder="质检时间"></el-input>
                        <span v-else>{{detailInfo.checkTime}}</span>
                    </el-form-item><br>
                    <el-form-item style="width:100%" label="备注:">
                        <el-input v-if="title=='完成质检'" type="textarea" rows="4" cols='100' v-model="qualityInfo.tips" placeholder="备注"></el-input>
                        <span v-else>{{detailInfo.tips}}</span>
                    </el-form-item><br>
                    <el-form-item style="width:100%" label="图片:">
                        <el-upload
                        v-if="title=='完成质检'"
                        class="upload-demo"
                        :headers="mytoken"
                        :action="action"
                        :on-preview="handlePreview"
                        :on-remove="picRemove"
                        :on-success="uploadImage"
                        list-type="picture"
                        multiple
                        accept=".jpg,.png"
                        :file-list="fileList">
                        <el-button size="small" style="margin-right:20px">点击上传</el-button>
                        <span slot="tip">大小不得超过10M,最多传5张</span>
                        </el-upload>
                        <div v-else style="display:flex;flex-wrap:wrap">
                            <div v-for="item in detailInfo.picList" :key="item.fileId" style="text-align:center">
                                <Picture :url='item.url' width='50' style="margin:auto"></Picture>
                                <p class="oneLine" style="width:100px">{{item.fileName}}</p>
                            </div>
                        </div>
                    </el-form-item><br>
                    <el-form-item style="width:100%" label="附件:">
                        <el-upload
                        v-if="title=='完成质检'"
                        class="upload-demo"
                        :action="action"
                        :headers="mytoken"
                        :on-remove="removeFile"
                        :on-success="uploadFile"
                        multiple
                        :file-list="fileList">
                        <el-button size="small" type="text" style="margin-right:20px">点击上传<i class="el-icon-upload2"></i></el-button>
                        </el-upload>
                        <div  v-for="item in detailInfo.fileList" :key="item.fileId">
                            <div style="cursor: pointer;" @click="down(item)" class="onLine fileList"><span style="color:orange" class="el-icon-paperclip"></span> {{item.fileName}}&nbsp;&nbsp;</div>
                        </div>
                    </el-form-item>
                </el-form>
            </div>
            <div slot="footer">
                <el-button @click="detailClose">关闭</el-button>
                <el-button v-if="detailInfo.status=='checked'">退换货</el-button>
                <el-button v-if="detailInfo.status=='checking'&&title=='质检单详情'" type="primary" @click="getDetail(detailInfo.id,'完成质检')">质检</el-button>
                <el-button v-if="detailInfo.status=='checked'" @click="revokedQualityCheck(detailInfo.id,detailInfo.qcNo)">撤销</el-button>
                <el-button :loading="dialogLoading" type="primary" @click="check" v-if="title!='质检单详情'">确 定</el-button>
            </div>
        </el-dialog>
        <!-- 添加采购退货单 -->
        <el-drawer
            title="添加采购退货单"
            :visible.sync="salesReturnOrder"
            direction="btt"
            size='88%'
            :before-close="salesReturnOrderClose">
            <div style="padding:20px">
                <h3>添加采购退货单</h3>
                <el-form style="margin-top:30px" ref="addform" :model="addform" :inline='true' label-width="100px">
                    <el-form-item label="收货仓库">
                        <el-select v-model="model" placeholder="">
                            <el-option
                                v-for="item in options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="收货类型">
                        <el-select v-model="model" placeholder="">
                            <el-option
                                v-for="item in options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="来源单据号">
                        <el-select v-model="model" placeholder="">
                            <el-option
                                v-for="item in options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="供应商">
                        <el-select v-model="model" placeholder="">
                            <el-option
                                v-for="item in options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="采购员">
                        <el-select v-model="model" placeholder="">
                            <el-option
                                v-for="item in options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="预计到货时间">
                        <el-select v-model="model" placeholder="">
                            <el-option
                                v-for="item in options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="物流商">
                        <el-select v-model="model" placeholder="">
                            <el-option
                                v-for="item in options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="物流单号">
                        <el-select v-model="model" placeholder="">
                            <el-option
                                v-for="item in options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="费用分配方式">
                        <el-select v-model="model" placeholder="">
                            <el-option
                                v-for="item in options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="运费">
                        <el-select v-model="model" placeholder="">
                            <el-option
                                v-for="item in options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="其他费用">
                        <el-select v-model="model" placeholder="">
                            <el-option
                                v-for="item in options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="附件">
                        <el-select v-model="model" placeholder="">
                            <el-option
                                v-for="item in options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="备注">
                        <el-select v-model="model" placeholder="">
                            <el-option
                                v-for="item in options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-form><br><br><br>

                <el-button>添加退货产品</el-button>
                <el-button>导入退货产品</el-button><br>
                <el-table
                style="width: 100%;margin-top:10px"
                :cell-style="{ fontSize: '13px', padding: '7px 0' }"
                :header-cell-style="{
                    fontSize: '13px',
                    padding: '9px 0',
                    marginTop: '10px',
                    background: '#EBEEF5',
                }">
                    <el-table-column
                        prop="prop"
                        label="图片" >
                    </el-table-column>
                    <el-table-column
                        prop="prop"
                        label="款名/SPU" >
                    </el-table-column>
                    <el-table-column
                        prop="prop"
                        label="品名/SKU" >
                    </el-table-column>
                    <el-table-column
                        prop="prop"
                        label="属性" >
                    </el-table-column>
                    <el-table-column
                        prop="prop"
                        label="店铺" >
                    </el-table-column>
                    <el-table-column
                        prop="prop"
                        label="MSKU/FNSKU" >
                    </el-table-column>
                    <el-table-column
                        prop="prop"
                        label="含税单价" >
                    </el-table-column>
                    <el-table-column
                        prop="prop"
                        label="采购数量" >
                    </el-table-column>
                    <el-table-column
                        prop="prop"
                        label="良品量可退量" >
                    </el-table-column>
                    <el-table-column
                        prop="prop"
                        label="良品退货量" >
                    </el-table-column>
                    <el-table-column
                        prop="prop"
                        label="次品可退量" >
                    </el-table-column>
                    <el-table-column
                        prop="prop"
                        label="扣款数量" >
                    </el-table-column>
                    <el-table-column
                        prop="prop"
                        label="退货金额" >
                    </el-table-column>
                    <el-table-column
                        prop="prop"
                        label="备注" >
                    </el-table-column>
                    <el-table-column
                        prop="prop"
                        label="操作" >
                    </el-table-column>
                </el-table>
                <div style="text-align:center;margin-top:50px">
                    <el-button>关闭</el-button>
                    <el-button type="primary">确定</el-button>
                </div>
            </div>
        </el-drawer>


        <!-- 大图显示容器 -->
        <ElImageViewer
        :z-index="9999999"
        :url-list="imgList"
        v-if="showViewer"
        :on-close="closeViewer"
        ></ElImageViewer>
    </div>
</template>

<script>
import {
    headList,
    pageInit,
    listPage,
    detail,
    batchFinishQualityCheckInit,
    batchFinishQualityCheck,
    cancelQualityCheck,
    getFile,
    revokedQualityCheck
} from "@/api/InventoryManage/quality.js"
import {Digits} from '@/utils/Digits.js'
import TableDialog from '@/components/tableDialog.vue'
import ElImageViewer from "element-ui/packages/image/src/image-viewer.vue";
export default {
    components:{
        TableDialog,
        ElImageViewer
    },
    data(){
        var spotCheckQuantityRule = (rule, value, callback) => {
            if(Number(value)>Number(this.detailInfo.checkingQuantity)){
                callback(new Error('抽检量不得大于质检量'))
            }else if(value<0){
                callback(new Error('抽检量不得小于0'))
            }else {
                callback()
            }
        }
        var spotCheckBadQuantity = (rule, value, callback) => {
            if(Number(value)>Number(this.qualityInfo.spotCheckQuantity)){
                console.log(1);
                callback(new Error('不得大于抽检量'))
            }else {
                callback()
            }
        }
        var badNumRules = (rule, value, callback) =>{
            if(Number(value)<Number(this.qualityInfo.spotCheckBadQuantity)){
                callback(new Error('不得小于抽检量次品量'))
            }else if(Number(value)<0){
                callback(new Error('抽检量不得小于0'))
            }else {
                callback()
            }
        }

        var token = window.sessionStorage.getItem('token')
        return {
            index:"",
            imgList:[],
            selectList:[],
            showViewer:false,
            headList:[],
            head:[],
            mytoken:{
                token:token
            },
            loading:false,
            dialogLoading:false,
            tableData:[1,2],
            batchFinish:false,
            batchReturn:false,
            addOrderBox:false,
            addReturnGoods:false,
            detailBox:false,
            imgList:[],
            showViewer:false,
            salesReturnOrder:false,

            // 查询初始化下拉
            timeSearchTypeList:[],
            checkStatusList:[],
            searchTypeList:[],

            // 详情
            detailInfo:{},
            fileList:[],
            // 质检
            qualityInfo:{
                badQuantity:0,
                fileIdList:[],
                goodQuantity:0,
                id:'',
                pictureIdList:[],
                spotCheckBadQuantity:0,
                spotCheckQuantity:0,
                tips:'',
            },
            percentPass:'',
            samplingRatio:'',
            // 分页信息
            pageInfo:{
                pageSize:20,
                current:1,
                checkStatus:'',
                timeType:'check_time',
                beginTime:'',
                endTime:'',
                searchType:'title',
                keyword:'',
                time:[]
            },

            qualityRules:{
                spotCheckQuantity:[
                    {required: true, message:'请输入抽检量', trigger: 'blur'},
                    {validator:spotCheckQuantityRule,trigger:'blur',}
                ],
                spotCheckBadQuantity:[
                    {required: true, message:'请输入次品量', trigger: 'blur'},
                    {validator:spotCheckBadQuantity,trigger:'blur'}
                ],
                goodQuantity:[{required: true, message:'请输入总良品量', trigger: 'blur'},{validator:spotCheckQuantityRule,trigger:'blur'}],
                badQuantity:[{required: true, message:'请输入总良品量', trigger: 'blur'},{validator:badNumRules,trigger:'blur'}],
            },
            total:0,
            pickerOptions:{
                disabledDate(time){
                    return  time.getTime() > new Date().getTime() - 8.64e6 ;
                }
            },

            // 批量完成质检
            batchFinishQualityCheckInitList:[]
        };
    },
    watch:{
        qualityInfo:{
            handler(newval,oldval){
                if(this.qualityInfo.spotCheckQuantity==0||!this.qualityInfo.spotCheckQuantity){
                    this.percentPass = '0%'
                }else{
                    this.percentPass = Digits((this.qualityInfo.spotCheckQuantity-this.qualityInfo.spotCheckBadQuantity)/this.qualityInfo.spotCheckQuantity,2)*100 + '%'
                }
                if(!this.qualityInfo.spotCheckQuantity){
                    this.samplingRatio='0%'
                    return
                } 
                this.samplingRatio = Digits(this.qualityInfo.spotCheckQuantity/this.detailInfo.checkingQuantity,2)*100 + '%'

                if(newval.badQuantity<newval.spotCheckBadQuantity) this.qualityInfo.badQuantity=this.qualityInfo.spotCheckBadQuantity
                this.qualityInfo.goodQuantity=Number(this.detailInfo.checkingQuantity)-this.qualityInfo.badQuantity
            },
            deep:true,
            immediate:true
        },
        $route(to){
            if(this.$route.query.id) this.getDetail(this.$route.query.id,'质检单详情')
        },
        batchFinishQualityCheckInitList:{
            handler(newval,oldval){
                this.batchFinishQualityCheckInitList.forEach(item=>{
                    if(item.spotCheckBadQuantity==0||item.spotCheckBadQuantity==''){
                        item.goodQuantity=item.checkingQuantity
                    }

                    if(item.spotCheckBadQuantity>item.badQuantity){
                        item.badQuantity=item.spotCheckBadQuantity
                    }
                    if(item.spotCheckQuantity<item.spotCheckBadQuantity){
                        item.spotCheckBadQuantity=item.spotCheckQuantity
                    }
                    item.goodQuantity=item.checkingQuantity-item.badQuantity
                })
            },
            deep:true,
            immediate:true
        }
    },
    computed:{
        action(){
            return process.env.VUE_APP_API_URL+'/api/file/upload/uploadFile'
        }
    },
    // created(){
    //     const id = this.$route.params.id
    //     if(id) this.getDetail(id,'质检单详情')
    // },
    mounted(){
        this.headList=headList
        this.head=headList
        this.getPageInit()
        this.getListPage()
    },
    filters:{
        Digits(value){
            return Digits(value*100,2) + '%'
        }
    },
    methods:{
        down(info){
            this.dialogLoading=true
            getFile(info.fileId).then(res=>{
                this.dialogLoading=false
                const blob = new Blob([res.data], { type: 'application/vnd.ms-excel application/x-excel;charset=utf-8'});
                const downloadElement = document.createElement('a');
                const href = window.URL.createObjectURL(blob); // 创建下载的链接
                downloadElement.href = href;
                downloadElement.download =info.fileName; // 下载后文件名
                document.body.appendChild(downloadElement);
                downloadElement.click(); // 点击下载
                document.body.removeChild(downloadElement); // 下载完成移除元素
                window.URL.revokeObjectURL(href); // 释放掉blob对象
            })
        },
        batUploadPic(res,file,fileList){
            console.log(file);
            if(res.code==200){
                this.$message.success(res.message)
                this.batchFinishQualityCheckInitList[this.index].picList.push({fileName:file.name,imageUrl:file.url})
                this.batchFinishQualityCheckInitList[this.index].pictureIdList.push(res.data.fileId)
            }else {
                this.$message.error(res.message)
            }
        },
        batchFinishQualityCheckInit(){
            if(this.selectList==0) return this.$message.error('请选择具体内容')
            this.loading=true
            const ids = this.selectList.map(item => item.id)
            batchFinishQualityCheckInit({ids:ids}).then(res=>{
                this.loading=false
                if(res.data.code==200){
                    this.batchFinish=true
                    this.batchFinishQualityCheckInitList=res.data.data.map(item=>{
                        item.picList=[]
                        item.pictureIdList=[]
                        item.badQuantity=0
                        item.spotCheckBadQuantity=0
                        item.spotCheckQuantity=0
                        return item
                    })
                }else{
                    this.$message.error(res.data.message)
                }
            })
        },
        batchSelect(val){
            this.selectList=val
        },
        cancelCheck(id,qcNo){
                this.$confirm(`确定取消质检 ${qcNo} ? 撤销后不可回复，且收货单对应未质检量将回退`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.loading=true
                cancelQualityCheck(id).then(res=>{
                    this.loading=false
                    this.$message.success(res.data.message)
                    if(res.data.code==200){
                        this.getListPage()
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消操作'
                });          
            });
        },
        revokedQualityCheck(id,qcNo){
                this.$confirm(`确定撤销 ${qcNo} ? 撤销后不可回复，且收货单对应未质检量将回退`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.loading=true
                revokedQualityCheck(id).then(res=>{
                    this.loading=false
                    if(res.data.code==200){
                        this.$message.success(res.data.message)
                        this.getListPage()
                        if(this.detailBox) this.detailClose()
                    }else{
                        this.$message.error(res.data.message)
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消操作'
                });          
            });
        },
        check(){
            this.$refs.quality.validate(valid=>{
                if(!valid) return
                this.finishCheck([this.qualityInfo])
            })
        },
        batchCheckSure(){
            const list = this.batchFinishQualityCheckInitList.map(item=>{
                return{
                    badQuantity:item.badQuantity,
                    fileIdList:[],
                    goodQuantity:item.goodQuantity,
                    id:item.id,
                    pictureIdList:item.pictureIdList,
                    spotCheckBadQuantity:item.spotCheckBadQuantity,
                    spotCheckQuantity:item.spotCheckQuantity,
                    tips:item.tips,
                }
            })
            this.finishCheck(list)
        },
        batchFinishClose(){
            this.batchFinishQualityCheckInitList=[]
            this.batchFinish=false
        },
        finishCheck(list){
            this.dialogLoading=true
            batchFinishQualityCheck({list:list}).then(res=>{
                this.dialogLoading=false
                if(res.data.code==200){
                    this.$message.success(res.data.message)
                    this.detailClose()
                    this.batchFinishClose()
                    this.getListPage()
                }else{
                    this.$message.error(res.data.message)
                }
            })
        },
        detailClose(){
            if(this.detailInfo.status=='checking'&&this.title=='完成质检') this.$refs.quality.resetFields()
            this.detailBox=false
            this.qualityInfo.tips=''
            this.detailInfo={}
            this.title=''
            this.$router.push('/quality')
        },
        uploadFile(res,file,fileList){
            if(res.code==200){
                this.qualityInfo.fileIdList.push(res.data.fileId)
                this.$message.success(res.message)
            }else {
                this.$message.error(res.message)
                fileList.splice(fileList.length-1,1)
            }
        },
        uploadImage(res,file,fileList){
            console.log(res,file,fileList);
            if(res.code==200){
                this.qualityInfo.pictureIdList.push(res.data.fileId)
                this.$message.success(res.message)
            }else{
                this.$message.error(res.message)
                fileList.splice(fileList.length-1,1)
            }
        },
        picRemove(file, fileList){
            console.log(file,fileList);
            this.qualityInfo.pictureIdList.splice(this.qualityInfo.pictureIdList.indexOf(file.response.data.fileId),1)
        },
        removeFile(file){
            this.qualityInfo.fileIdList.splice(this.qualityInfo.fileIdList.indexOf(file.response.data.fileId),1)
        },
        handlePreview(file){
            console.log(file);
            this.imgList=[file.url]
            this.showViewer=true
        },
        closeViewer(){
            this.imgList=[]
            this.showViewer=false
        },
        getDetail(id,title){
            this.qualityInfo.id=id
            this.title=title
            this.loading=true
            this.$nextTick(()=>{
                this.$refs.quality.resetFields()
            })
            detail(id).then(res=>{
                console.log(res);

                this.loading=false
                if(res.data.code==200){
                    this.detailBox=true
                    for(var key in res.data.data){
                        this.$set(this.detailInfo,key,res.data.data[key])
                    }
                    this.fileList=res.data.fileList
                    console.log(this.detailInfo);
                }else {
                    this.$message.error(res.data.message)
                }
            })
        },

        gethead(val){
            this.head=val
        },
        handleSizeChange(val){
            this.pageInfo.pageSize=val
            this.getListPage()
        },
        handleCurrentChange(val){
            this.pageInfo.current=val
            this.getListPage()
        },
        getPageInit(){
            pageInit().then(res=>{
                console.log(res);
                this.checkStatusList=res.data.data.checkStatusList
                this.timeSearchTypeList=res.data.data.timeSearchTypeList
                this.searchTypeList=res.data.data.searchTypeList
            })
        },
        getListPage(){
            this.loading=true
            listPage(this.pageInfo).then(res=>{
                this.loading=false
                console.log(res);
                this.tableData=res.data.values
                this.total=res.data.pageInfo.total
                if(this.$route.query.id) this.getDetail(this.$route.query.id,'质检单详情')
            })
        },
        getTime(){
            this.pageInfo.beginTime=this.pageInfo.time[0]
            this.pageInfo.endTime=this.pageInfo.time[1]
            this.getListPage()
        },
        find(){
            this.pageInfo.current=1
            this.getListPage()
        }
    }
    
}
</script>

<style scoped lang='scss'>
.Box{
    width: 100%;
    height: calc(100% - 68px);
    display: flex;
    flex-direction: column;
    .content{
        flex: 1;
        overflow: auto;
        padding-top: 10px;
    }
}
::v-deep .el-input-group__prepend {
    background-color: #fff;
}
::v-deep .el-input-group__append {
    background-color: #fff;
}
::v-deep .el-drawer__header{
    display: none;
}
.sign{
    padding: 0px 3px;
    border-radius: 4px;
    font-size: 12px;
}
.detail{
    display:flex;flex-wrap:wrap;
    line-height: 30px;
    margin-top: 20px;
    margin-bottom:50px;
    >div{
        width:33%;display:flex;
        >div:nth-of-type(1){
            width:100px;text-align:right;
        }
        >div:nth-of-type(2){
            width:calc(100% - 100px)
        }
    }
}
.status{
    >span{
        display: inline-block;
        padding: 2px 2px;
        border: 1px solid #A9ABAD;
        line-height: 1;
        border-radius: 4px;
    }
}
.oneline{
    white-space: nowrap;  
    text-overflow:ellipsis;
    overflow:hidden;
}
// .fileList{
//     >i{
//         border-radius: 50%;
//         font-size: 10px;
//         transition: all 0.5s;
//     }
// }
// .fileList:hover{
//     >i{
//         display: inline-block;
//         color: white;
//         background: #999999;
//         padding: 1px;
//     }
// }
</style>